import React from 'react'
import Privcy from '../pages/Privcy'
import '../../styles/privacy.css'

const Return = () => {
  return (
    <>
      <div className="w-full h-full my-5 px-4 py-2 flex items-center justify-center">
        <div className="w-full md:w-4/5 flex flex-col items-center justify-center">
          <Privcy />
          <div className="text-area">
            Every product is checked thoroughly before it is shipped. Therefore, we don't accept returns/refunds/exchanges for any products purchased online.
          </div>
        </div>
      </div>
    </>

  )
}

export default Return
