import {
  ADD_TO_WHISLIST,
  DELETE_WISHLIST,
  GET_WISHLIST,
} from "../actions/types";
import { produce } from "immer";

const wishListReducer = (state = [], action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_TO_WHISLIST:
        draft.push(action.payload);
        break;
      case GET_WISHLIST:
        return action.payload;
      case DELETE_WISHLIST:
        const index = draft.findIndex(
          (item) => item.id === action.payload);
        if (index !== -1) draft.splice(index, 1);
        break;
      default:
        return state;
    }
  });
};

export default wishListReducer;
