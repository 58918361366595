import React, { useEffect, useState } from 'react'
import { PAINTING1, PAINTING3 } from '../../assets'
import { firestore } from '../../firebase';
import PaintingCard from '../cards/PaintingCard';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner'
import Msg from '../notification/Msg'
import Scroller from '../Scroller';
import Slider from '../Slider';

const RelatedProducts = ({ product }) => {
  const [products, setProducts] = useState(new Set());
  const maxCards = 8;
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const categories = product.categories;
    setLoading(true);
    let items = [];

    firestore.collection('PRODUCTS').get().then((querySnapShot) => {
      let products = querySnapShot.docs.map((item) => item.data());
      return products;
    }).then((products) => {
      for (let i = 0; i < categories.length; i++) {
        products.forEach((p) => {
          if (p.categories.find((item) => item.id === categories[i].id)) {
            // if not exists in current items then include
            if (!items.includes(p) && items.length < 8) {
              items.push(p);
            }
          }

        })
      }
    }).then(() => {
      setLoading(false);
      setProducts(items);
    })
  }, [product]);

  return (
    <>
      {product && product.categories &&
        <div className="related-products mb-12  px-5 md:px-0">
          <div className="flex items-center justify-between w-full md:w-4/5">
            <h1 className="text-xl md:text-2xl font-bold uppercase text-primary my-4">Related Paintings</h1>
            <Link to={`/category/${product.categories[0].name}`}>
              <button className="w-24 md:w-32 text-sm md:text-base py-1 bg-transparent border border-primary uppercase">View all</button>
            </Link>
          </div>
          <div className="w-full md:w-4/5 mx-auto flex flex-col justify-start">
            {products.length > 0 ? (
              <Slider>
                {products.map((product) => (
                  <div className="w-64 flex-none mr-5 text-center">
                    <PaintingCard product={product} />
                  </div>
                ))}
              </Slider>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      }
    </>
  );
}

export default RelatedProducts
