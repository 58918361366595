import React, { useEffect, useState } from 'react'
import './inputstyle.css'
import firebase from '../../firebase'
import { connect } from 'react-redux'
import { sigin, submitOtp, login } from '../../actions'
import { COLLAGE, KALAVARANA_LOGO } from '../../assets';

import TextField from '@material-ui/core/TextField';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import Loader from "react-loader-spinner";
import { history } from '../../history'

const LoginComp = (props) => {
  const [otp, setOtp] = useState('')
  const [number, setNumber] = useState('')
  const [isNum, setIsNum] = useState(true);
  const [loading, setIsLoading] = useState(false)
  const [phoneCount, setPhoneCount] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const dispatch = props.dispatch;

  useEffect(() => {
    setIsLoading(false);
  }, [props.noti.err]);

  useEffect(() => {
    if (props.user && props.user.id) {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push('/')
      }
    }
  }, [props.user]);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          return null;
        },
        defaultCountry: "IN",
      }
    );
  };

  const onSignInSubmit = () => {
    if (phoneCount != number.length) {
      setIsNum(false);
    } else {
      setIsNum(true)
      setIsLoading(true)
      setUpRecaptcha();
      props.login(`+${number}`, setIsLoading, setShowOTP);
    }
  };

  const onSubmitOtp = () => {
    props.submitOtp(otp, history, setIsLoading)
  };

  const onSubmit = () => {
    if (showOTP) {
      onSubmitOtp();
    } else {
      onSignInSubmit();
    }
  }

  return (
    <div className="flex flex-col md:flex-row items-center justify-center w-full h-full min-h-screen max-h-screen overflow-hidden">
      <div className="w-full md:w-3/5 h-screen overflow-hidden">
        <img src={COLLAGE} alt="loginImg" style={{ width: "100%", height: "100%" }} className="w-full h-full object-contain object-top md:object-cover md:object-left" />
      </div>
      <div className="w-full md:w-2/5 absolute md:static bg-white min-h-[400px] h-2/5 md:h-full bottom-0 bg-none backdrop-blur-sm
        flex flex-col items-center justify-evenly text-sm rounded  px-12">
        <div className="flex flex-col text-sm w-full">
          <img
            src={KALAVARANA_LOGO}
            alt="ANA"
            style={{ width: "125px" }}
            className="hidden md:flex mx-auto sm:-ml-2" />
          <h1 className="text-xl font-normal py-3 pb-5 text-primary flex items-center">
            {/* <AiOutlineArrowLeft className="text-xl mr-4" onClick={() => navigate(-1)} /> */}
            Welcome to Kalavarana
          </h1>
          <div className="my-3 w-full max-w-sm">
            {/* <p className="text-sm mb-1">Phone Number</p> */}
            <PhoneNumberInput
              value={number}
              containerStyle={{ margin: 0 }}
              setValue={setNumber}
              setPhoneCount={setPhoneCount}
            />
            {!isNum && <p className="text-red-500">Number is required</p>}
          </div>

          {showOTP &&
            <div className="my-3 w-full max-w-sm">
              <p className="text-sm mb-1">Enter OTP</p> <div className="pr-4 w-full flex items-center outline-none border border-[#cacaca] max-w-sm">
                <input value={otp} onChange={(e) => setOtp(e.target.value)} className=" w-full px-3 py-4  outline-none text-base" type="text" placeholder="OTP" />
                <span
                  className="h-full flex items-center justify-center text-black cursor-pointer px-2 md:px-3 hover:underline"
                  onClick={() => dispatch(login(`+${number}`, setIsLoading, setShowOTP))}>
                  RESEND
                </span>
              </div>
            </div>
          }
          <button onClick={() => onSubmit()} className="w-full md:w-32 flex justify-center items-center py-3 px-3 my-2 text-white mt-8 bg-primary">
            {loading ? (
              <Loader type="TailSpin" color="#fff" height={15} width={15} />
            ) :
              showOTP ? "Login" : "Send OTP"
            }
          </button>
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    msg: state.notification,
    mobNo: state.mobNo.mobNo,
    showOtp: state.showOtp.showOtp,
    noti: state.notification,
    user: state.user.user
  }
}
export default connect(mapStateToProps, { login, sigin, submitOtp })(LoginComp)
