import { MSG } from "../actions/types";
const notificationReducer = (state = { msg: "", err: false, warning: false, success: false }, action) => {
  switch (action.type) {
    case MSG:
      return { ...state, msg: action.payload.msg, err: action.payload.err, warning: action.payload.warning, success: action.payload.success };
    default:
      return state;
  }
};

export default notificationReducer;
