import produce from "immer";
import { CANCEL_ORDER, GET_ORDERS, PLACE_ORDER } from "../actions/types";

const orderReducer = (state = [], action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PLACE_ORDER:
        draft.unshift(action.payload);
        break;
      case GET_ORDERS:
        return [...action.payload]
      case CANCEL_ORDER:
        let index = draft.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) draft[index] = { ...action.payload };
        break;
      default:
        return state
    }
  })
}

export default orderReducer