import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useState } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { notification, userStateChanged } from '../../actions';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import LoadingSpinner from '../LoadingSpinner'
import ProfileNavigation from './ProfileNavigation';
import { Redirect } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import { useForm } from 'react-hook-form';


const UserInfo = (props) => {
  const user = props.user;
  const { register, handleSubmit, formState: { errors, isDirty } } = useForm({
    defaultValues: {
      name: user.name,
      email: user.email,
    }
  });
  const [phoneNumber, setPhoneNumber] = useState(props.user.phoneNumber);
  const [isNumber, setIsNumber] = useState(true)
  const [phoneCount, setPhoneCount] = useState(0);

  useEffect(() => {
    if (user.id) {
      setPhoneNumber(user.phoneNumber);
      setPhoneCount(user.phoneNumber.length)
    }
  }, [user.id, user.phoneNumber]);

  const onSubmit = (data) => {
    if (phoneCount !== phoneNumber?.length) {
      setIsNumber(false);
      return;
    } else {
      setIsNumber(true);
      db.users
        .doc(user.id)
        .update({
          name: data.name,
          phoneNumber: `${phoneNumber}`,
          email: data.email,
        })
        .then(() => {
          props.notification({ msg: "profile updated" });
          db.users.doc(props.user.id).get().then((doc) => {
            props.userStateChanged(doc.data());
          })
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }

  if (!props.user.name) {
    return <Redirect to="/login" replace={true} />
  }

  return (
    <>
      <div className="w-full h-full mt-5 flex flex-col md:flex-row items-start justify-center">
        <div className="relative w-full md:w-[90%] max-w-screen-2xl mx-auto p-2 px-4 h-full flex flex-col items-start justify-center">
          <ProfileHeader />
          <div className='w-full flex items-start justify-center'>
            <div className="hidden md:flex sticky-scroll-container">
              <ProfileNavigation />
            </div>
            {props.user.id ?
              <div className="w-full md:w-3/5 md:pl-12 md:ml-auto flex flex-col justify-center ml-auto ">
                <div className="flex flex-col justify-start">
                  <h1 className="text-primary flex items-center justify-start md:hidden text-2xl font-medium">
                    <span className="pr-2"><Link replace to={'/profile-and-details'}><KeyboardBackspaceIcon /></Link></span>
                    Profile Details</h1>
                  <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className="my-3 w-full max-w-sm">
                      <p className="text-sm mb-1">Name</p>
                      <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="text" placeholder="Name" {...register("name", { required: true, min: 3, maxLength: 80 })} />
                      {errors.name && <span className="text-xs text-red-400">This field is required</span>}
                    </div>
                    <div className="my-3 w-full max-w-sm">
                      <p className="text-sm mb-1">Email</p>
                      <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="email" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                      {errors.email && <span className="text-xs text-red-400">This field is required</span>}
                    </div>
                    <div className="my-3 w-full max-w-sm">
                      <p className="text-sm mb-1">Phone Number</p>
                      <PhoneNumberInput
                        value={phoneNumber}
                        inputStyle={{ fontSize: 16, color: "black" }}
                        containerStyle={{ margin: 0 }}
                        setPhoneCount={setPhoneCount}
                        setValue={setPhoneNumber}
                      />
                      {!isNumber && <p className="text-xs text-red-400">Number is required</p>}
                    </div>

                    <button disabled={(phoneNumber === user.phoneNumber) && !isDirty}
                      type="submit" className={`update-profile-btn ${(phoneNumber === user.phoneNumber) && !isDirty ? "bg-primary/50" : "bg-primary"}`}>
                      Update Info
                    </button>
                  </form>
                </div>
              </div>
              :
              <>
                <LoadingSpinner />
              </>}
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) => {
  return { user: state.user?.user }
}
export default connect(mapStateToProps, { notification, userStateChanged })(UserInfo)
