import React, { useEffect, useState } from 'react'
import '../styles/Header.css'
import { BiSearch } from 'react-icons/bi'
import { AiOutlineUser } from 'react-icons/ai'
import { FiShoppingCart, FiMenu } from 'react-icons/fi'
import { IoMdArrowDropdown, IoMdClose } from 'react-icons/io'
import { FiArrowRight } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { KALAVARANA_LOGO2X } from '../assets';
import { firestore } from '../firebase'
import MenuDropDown from './MenuDropDown'
import { history } from '../history'

const Header = (props) => {
  const [showSideBar, setShowSideBar] = useState(false)
  const [categories, setCategories] = useState([]);
  const [showHeader, setShowHeader] = useState({ show: true, scrollPos: 0 });

  useEffect(() => {
    let items = [];
    firestore.collection('CATAGORIES').get().then((snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        items[i] = { cat: snapshot.docs[i].data(), subcats: [] };
        firestore.collection('SUB-CATAGORIES').get().then((querySnapshot) => {
          querySnapshot.forEach(doc => {
            let x = doc.data();
            if (x.category.name === snapshot.docs[i].data().name) {
              items[i].subcats.push({ ...x });
            }
          })
        })
      }
      setCategories(items);
    }).
      catch(err => console.log(err));
  }, []);

  useEffect(() => {

  }, [categories])

  const handleScroll = () => {
    if (window.scrollY < 100) {
      setShowHeader((prev) => ({ ...showHeader, show: true }));
    } else {
      setShowHeader((prev) => ({
        scrollPos: document.body.getBoundingClientRect().top,
        show: document.body.getBoundingClientRect().top > prev.scrollPos
      }))
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const path = useLocation().pathname;
  if (path === '/login' || path === '/signup' || path === '/checkout' || path === "/cart" || path.startsWith("/profile") || path === "/return-policy" || path === "/terms&conditions" || path === "/privacy-policy") {
    return null;
  }


  return (
    <div className={`fixed border-b border-gray-200 bg-white top-0 left-0 flex flex-col w-full h-20 ${(path.startsWith("/profile") || path === "/cart" ? "md:h-28" : "md:h-36")} pt-4 px-3 z-40 transition-all select-none
			${!showHeader.show ? '-top-20 md:-top-36' : 'top-0'} 
		`}>
      <div className="w-full h-full md:w-4/5 mx-auto">
        <div className="div">
        </div>
        <div className="w-full h-3/5 relative flex items-center justify-around">
          {(!path.startsWith("/profile") && path !== "/cart") && <div className="md:hidden" onClick={() => { setShowSideBar(true) }}>
            <FiMenu className="text-xl" />
          </div>
          }
          <div className="absolute mx-auto">
            <Link to="/"><img src={KALAVARANA_LOGO2X} alt="KALAVARNA" className="w-auto h-12 md:h-20 md:mx-auto" /></Link>
          </div>
          <div className="flex ml-auto justify-evenly text-black">
            {/* <BiSearch className="text-xl mx-3" /> */}
            {path.startsWith("/profile") || path === "/cart" ?
              <Link to=""><IoMdClose onClick={() => history.goBack()} className='md:block text-2xl mx-3' /></Link>
              :
              <>
                <Link to={!props.user.id ? '/login' : '/profile'}><AiOutlineUser className="block text-xl mx-3" /></Link>
                <Link to="/cart">
                  <div className="relative">
                    <span className="inline-block absolute -top-2 right-0 bg-primary w-4 h-4 text-white text-center text-xs rounded-full">{props.cart?.length}</span>
                    <FiShoppingCart className="text-xl mx-3" />
                  </div>
                </Link>
              </>
            }

          </div>
        </div>
        {(!path.startsWith("/profile") && path !== "/cart") &&

          <div className="w-full md:flex px-10 py-0 md:py-5 items-center justify-center ">
            <ul className={`nav-links ${showSideBar && 'show-side-bar'} cursor-pointer upper-case flex items-center select-none z-50`}>
              <div className={`w-full px-4 h-20 items-center bg-primary justify-start ${showSideBar ? 'flex' : "hidden"}`}>
                {props.user.id ?
                  <Link to="/profile-and-details" onClick={() => setShowSideBar(false)}>
                    <div className='w-full flex items-center'>
                      <AiOutlineUser className="text-base mr-2 text-white" />
                      <p className="px-3 text-white">{props.user?.name}</p>
                      <FiArrowRight className="text-base text-white ml-auto" />
                    </div>
                  </Link>
                  :
                  <div className="w-full h-full flex items-center justify-center">
                    <Link to="/login" onClick={() => setShowSideBar(false)}>
                      <button className="bg-white text-primary uppercase px-4 py-2">Login</button>
                    </Link>
                  </div>
                }
              </div>

              <Link to="/sales" onClick={() => setShowSideBar(false)}>
                <li className="relative hover:text-[#E5AC74]">Sales</li>
              </Link>
              {categories.length > 0 && categories.map((item) => (
                <>
                  <MenuDropDown
                    to={`/category/${item.cat.name}`}
                    options={item.subcats}
                    setShowSideBar={setShowSideBar}
                    title={item.cat.name}
                    uppercase
                    key={item.cat.id}
                  />
                </>
              ))}

              <Link to="/about" onClick={() => setShowSideBar(false)}><li className=" whitespace-nowrap text-sm hover:text-[#E5AC74]">About us</li></Link>
              <Link to="/blog" onClick={() => setShowSideBar(false)}><li className=" whitespace-nowrap text-sm hover:text-[#E5AC74]">Blog</li></Link>
              <Link to="/connect" onClick={() => setShowSideBar(false)}><li className=" whitespace-nowrap text-sm hover:text-[#E5AC74]">Contact us</li></Link>
            </ul>
            <div onClick={() => setShowSideBar(false)} className={`blank-screen ${showSideBar && 'show-side-bar'}`}>
            </div>
          </div>
        }
      </div >
    </div >
  )
}




const mapStateToProps = (state) => {
  return { cart: state.cart, user: state.user.user }
}

export default connect(mapStateToProps)(Header)
