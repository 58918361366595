import { NOT_SINGUPED } from "../actions/types";


const notSignedUserReducer = (state = {}, action) => {
  switch (action.type) {
    case NOT_SINGUPED:
      return action.payload;
    default:
      return state;
  }
};

export default notSignedUserReducer;
