import { db } from "../firebase"
import { CANCEL_ORDER, DELETE_CART_ITEM, GET_ORDERS, PLACE_ORDER } from "./types"


export const placeOrder = (data) => async (dispatch, getState) => {
  const cart = getState().cart
  const userID = getState().user?.user?.id;
  const orderRef = db.orders.doc();
  const user = getState().user?.user;
  data = { ...data, id: orderRef.id, user, items: cart, bookingTime: Date.now() };
  let orderData = {
    id: orderRef.id,
    user: data.user,
    address: data.address,
    totalMrp: data.grandTotal,
    items: data.items,
    bookingTime: Date.now(),
    state: "newOrder",
    deliveryCharge: data.deliveryCharge,
    isPaymentDone: data.isPaymentDone,
    paymentId: data.paymentId,
    deliveredOn: 0,
    donation: data.donation
  }
  orderRef.set({
    ...orderData
  }).then(() => {
    dispatch({ type: PLACE_ORDER, payload: { ...orderData } })
    data.items.forEach((item, i) => {
      db.users
        .doc(userID)
        .collection('CARTITEMS')
        .doc(item.product.id)
        .delete()
        .then(() => {
          dispatch({ type: DELETE_CART_ITEM, payload: item.product.id })
        })
    })
  }).catch((err) => {
    console.log(err);
  })
}

export const getOrders = () => async (dispatch, getState) => {
  const userID = getState().user?.user?.id
  db.orders.where('user.id', '==', userID)
    .get().then((snapshot) => {
      dispatch({ type: GET_ORDERS, payload: snapshot.docs.map(db.formatedDoc) })
    })
}

export const cancleOrder = (id, product) => (dispatch, getState) => {
  const userId = getState().user?.user?.userId
  db.orders.doc(id).update({
    state: 'canceled'
  })
    .then(() => {
      dispatch({ type: CANCEL_ORDER, payload: id })
    }).catch((err) => console.log(err));
}