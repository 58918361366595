import { db } from '../firebase'
import { ADD_TO_WHISLIST, DELETE_WISHLIST, GET_WISHLIST, MSG } from './types'

import { notification } from './index'
export const addToWhislist = (product) => (dispatch, getState) => {
  const userID = getState().user?.user?.id;
  const wishlist = getState().wishlist;
  const itemExists = wishlist.find((item) => item.id === product.id);
  if (itemExists) {
    dispatch(notification({ msg: "Already in your wishlist" }));
  } else {
    db.users
      .doc(userID)
      .collection("WISHLIST")
      .doc(product.id)
      .set({
        ...product,
      })
      .then(() => {
        dispatch({ type: ADD_TO_WHISLIST, payload: product });
        dispatch(
          notification({ msg: "Product added to wishlist", success: true })
        );
      })
      .catch((err) => {
        dispatch(
          notification({ msg: "Unable added to wishlist", err: true })
        );
      });
  }
}


export const deleteWishList = (id) => (dispatch, getState) => {
  const userId = getState().user?.user?.id;
  db.users
    .doc(userId)
    .collection("WISHLIST")
    .doc(id)
    .delete()
    .then(() => {
      dispatch({ type: DELETE_WISHLIST, payload: id });
      dispatch(
        notification({ msg: "Product removed from wishlist", success: true })
      );

    })
    .catch((err) => console.log(err));
}

export const getWishList = () => (dispatch, getState) => {
  const userID = getState().user?.user?.id;
  db.users.doc(userID).collection('WISHLIST').get().then((snapshot) => {
    dispatch({ type: GET_WISHLIST, payload: snapshot.docs.map(db.formatedDoc) })
  })
}
