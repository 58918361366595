import React, { useEffect } from 'react'
import '../../styles/sidecart.css'
import CloseIcon from '@material-ui/icons/Close';
import { showCart } from '../../actions/cart'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SideCartItem from './SideCartItem';
const SideCart = (props) => {

  useEffect(() => {

  }, [props]);
  // console.log(props.cartItems);
  return (
    <div onClick={() => props.showCart(false)} className={`cart-full ${props.showcart && 'show-side-cart'}`}>
      <div className="side-cart" onClick={(e) => e.stopPropagation()}>
        <div className="cart-title">
          <h1 className="text-2xl font-bold">Cart</h1>
          <div className="close cursor-pointer" onClick={() => props.showCart(false)}>
            <CloseIcon />
          </div>
        </div>
        {props.cartItems.length == 0 &&
          <div className="w-full flex items-start justify-center pt-28">
            <p className="text-xl text-center font-medium text-gray-400">Your cart is currently empty.</p>
          </div>
        }
        <div className="side-cart-items">
          {props.cartItems?.map((cart, i) => (
            <SideCartItem
              key={cart.product.id}
              product={cart.product}
              quantity={cart.quantity}
            />
          ))}
        </div>
        <div className="w-full flex flex-col items-center justify-between absolute bottom-0 left-0 bg-white md:p-4">
          <div className="side-cart-check flex justify-between items-end bg-gray-200 md:p-4">
            <p className="text-base font-normal">No of items: <span className="text-lg font-medium"> {props.cartItems.length} </span></p>
            <p className="text-base font-normal flex items-center whitespace-nowrap">Total:&nbsp;<span className="text-lg font-semibold">{props.total}</span></p>
          </div>
          <div className="w-full h-full mt-2">
            <Link to="/cart">
              <button className="w-full bg-primary text-white py-3 md:py-4 outline-none border-0 ">CHECKOUT</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return { cartItems: state.cart, showcart: state.showcart.showcart, total: state.cartTotal.total }
}

export default connect(mapStateToProps, { showCart })(SideCart)
