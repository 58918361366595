import React, { useEffect, useState } from 'react'
import '../../styles/orders.css'
import ProfileNavigation from '../profile/ProfileNavigation'
import { connect } from 'react-redux'
import OrderCard from '../cards/OrderCard'
import { Link } from 'react-router-dom';
import { MdArrowBack, MdOutlineSort } from "react-icons/md";

import ProfileHeader from '../profile/ProfileHeader'

const Orders = (props) => {
  const [filtered, setFiltered] = useState([]);
  const [orders, setOrders] = useState(props.orders.slice());
  const [showFilter, setShowFilter] = useState(false);



  useEffect(() => {
    let x = props.orders.slice();
    x = x.sort((a, b) => a.bookingTime < b.bookingTime ? 1 : -1);
    setFiltered(x);
  }, [props.orders]);



  const Filters = [
    { name: 'Newest' },
    { name: 'Oldest' }
  ]

  const Newest = () => {
    setFiltered(props.orders.sort((a, b) => a.bookingTime < b.bookingTime ? 1 : -1))
  }

  const Oldest = () => {
    setFiltered(props.orders.sort((a, b) => a.bookingTime < b.bookingTime ? -1 : 1))
  }

  const FilterUtils = (type) => {
    switch (type) {
      case 'Newest':
        setFiltered(props.orders.sort((a, b) => a.bookingTime < b.bookingTime ? 1 : -1))
        setShowFilter(false);
        break;
      case 'Oldest':
        setFiltered(props.orders.sort((a, b) => a.bookingTime < b.bookingTime ? -1 : 1))
        setShowFilter(false);
        break;
      default:
        return;

    }
  }

  console.log(filtered);

  return (
    <div className="w-full h-full mt-5 flex flex-col md:flex-row items-start justify-center">
      <div className="relative w-full md:w-[90%] max-w-screen-2xl mx-auto p-2 px-4 h-full flex flex-col items-start justify-center">
        <ProfileHeader />
        <div className='w-full flex items-start justify-center'>
          <div className="hidden md:flex sticky-scroll-container">
            <ProfileNavigation />
          </div>
          <div className="w-full md:w-3/5 md:pl-12 md:ml-auto flex flex-col justify-center ml-auto ">
            <div className="flex flex-col justify-start">
              <div className="w-full flex justify-between items-start">
                <h1 className="text-primary flex items-center justify-start mb-3 md:mb-8 md:hidden text-2xl font-medium">
                  <span className="pr-2 md:hidden">
                    <Link replace to={"/profile-and-details"}>
                      <MdArrowBack />
                    </Link>
                  </span>
                  My Orders
                </h1>
                {/* <div className="relative flex h-9 items-center justify-between border px-2 py-1 cursor-pointer" onClick={() => setShowFilter(!showFilter)}>
                <MdOutlineSort className="mx-1" />
                Sort
                {showFilter &&
                  <div className="absolute top-9 z-40 right-0 bg-white shadow-md border rounded flex flex-col items-end ">
                    {Filters.map((filter) => <p className="w-full py-2 px-4 hover:bg-gray-100 text-sm text-right" onClick={() => FilterUtils(filter.name)}>{filter.name}</p>)}
                  </div>
                }
              </div> */}
              </div>

              {props.orders.length > 0 && filtered.length > 0 ? (
                filtered.map((order, i) => (
                  <div className="mb-5" key={order.id}>
                    <OrderCard key={order.id} order={order} />
                  </div>
                ))
              )
                : (
                  <h1 className="h-64 flex items-center justify-center text-gray-400 text-2xl">
                    You haven't ordered anything yet.
                  </h1>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
const mapStateToProps = (state) => {
  return { orders: state.orders }
}

export default connect(mapStateToProps)(Orders)
