import React, { useEffect, useRef, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useDrag, useTouchDrag } from "../hooks";



const Slider = ({ slideItems = 1, children }) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const { touchDragStart, touchDragStop, touchDragMove, touching } =
    useTouchDrag();
  const [currentIndex, setCurrentIndex] = useState(0);
  const contaninerRef = useRef(null);
  const innerRef = useRef(null);
  const [isFirstItemVisible, setIsFirstItemVisible] = useState(true);
  const [isLastItemVisible, setIsLastItemVisible] = useState(true);
  const [itemWidth, setItemWidth] = useState(0);

  let options = {
    root: contaninerRef.current,
    rootMargin: "0px",
    threshold: 0.95,
  };

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (innerRef.current) {
        if (entry.target === innerRef?.current.children[0]) {
          if (entry.isIntersecting) {
            setIsFirstItemVisible(true);
          } else {
            setIsFirstItemVisible(false);
          }
        }
        if (
          entry.target ===
          innerRef?.current.children[innerRef?.current.children.length - 1]
        ) {
          if (entry.isIntersecting) {
            setIsLastItemVisible(true);
          } else {
            setIsLastItemVisible(false);
          }
        }
      }
    });
  };

  useEffect(() => {
    let first;
    let last;
    if (innerRef.current) {
      first = innerRef.current.children[0];
      last = innerRef.current.children[innerRef.current.children.length - 1];
      const observer = new IntersectionObserver(callback, options);
      observer.observe(first);
      observer.observe(last);

      setItemWidth(
        Number(first.clientWidth) +
        Number(
          window.getComputedStyle(first).marginRight.replace(/px/g, "")
        ) +
        Number(window.getComputedStyle(first).marginLeft.replace(/px/g, ""))
      );
      return () => {
        observer.unobserve(first);
        observer.unobserve(last);
      };
    }
  }, [innerRef, contaninerRef, options]);

  const handleDrag = (ev) => {
    dragMove(ev, (posDiff) => {
      if (contaninerRef.current) {
        contaninerRef.current.scrollLeft += posDiff;
      }
    });
  };

  const handleTouch = (e) => {
    touchDragMove(e, (posDiff) => {
      if (contaninerRef.current) {
        contaninerRef.current.scrollLeft += posDiff;
      }
    });
  };
  return (
    <div
      onMouseDown={dragStart}
      onMouseMove={handleDrag}
      onMouseLeave={dragStop}
      onMouseUp={dragStop}
      onMouseUpCapture={dragStop}
      onTouchStart={touchDragStart}
      onTouchMove={handleTouch}
      onTouchEnd={touchDragStop}
      onTouchCancel={touchDragStop}
    >
      <div className={`relative w-full h-full select-none`}>
        {!isFirstItemVisible && (
          <button
            className="absolute h-full flex  items-center justify-center -left-2 md:-left-14 outline-none select-none"
            onClick={() =>
              currentIndex > 0
                ? setCurrentIndex(currentIndex - 1)
                : setCurrentIndex(0)
            }
          >
            <BsChevronLeft className="text-3xl md:text-4xl border border-gray-600 z-20 rounded-full p-2 bg-primary text-white select-none" />
          </button>
        )}
        <div
          className={`w-full relative h-full min-h-fit overflow-hidden`}
          ref={contaninerRef}
        >
          <div
            className={`w-full h-full flex relative transition-all select-none`}
            ref={innerRef}
            style={{ left: `-${currentIndex * itemWidth * slideItems}px` }}
          >
            {children}
          </div>
        </div>
        {!isLastItemVisible && (
          <button
            className="absolute h-full flex items-center justify-center -right-2 md:-right-14 top-0 outline-none select-none"
            onClick={() => setCurrentIndex(currentIndex + 1)}
          >
            <BsChevronRight className="text-3xl md:text-4xl border  border-gray-600 z-20 rounded-full p-2 bg-primary text-white" />
          </button>
        )}
      </div>
    </div>
  );
};
export default Slider;