import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { history } from '../../history'
import ProfileNavigation from './ProfileNavigation'
import { getAddresses } from '../../actions/address'
import AddressCard from './AddressCard'
import AddressForm from './AddressForm';
import Msg from '../notification/Msg'
import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ProfileHeader from './ProfileHeader'

const Adress = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const getActiveAdd = (addIndex) => {
    setActiveIndex(addIndex)
  }

  return (
    <>
      <div className="w-full h-full mt-5 flex flex-col md:flex-row items-start justify-center">
        <div className="relative w-full md:w-[90%] max-w-screen-2xl mx-auto p-2 px-4 h-full flex flex-col items-start justify-center">
          <ProfileHeader />
          <div className='w-full flex items-start justify-center'>
            <div className="hidden md:flex sticky-scroll-container">
              <ProfileNavigation />
            </div>
            <div className="w-full md:w-3/5 md:pl-10 md:ml-auto flex flex-col justify-center ml-auto ">
              <div className="md:mr-auto">
                <h1 className="text-primary flex items-center justify-start md:hidden text-2xl font-medium">
                  <span className="pr-2"><Link replace to={'/profile-and-details'}><KeyboardBackspaceIcon /></Link></span>
                  My Address</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 place-items-center">
                  {
                    props.addresses.map((address, i) => (
                      <AddressCard
                        key={i} getActiveAdd={getActiveAdd}
                        i={i}
                        address={address}
                      />
                    ))
                  }
                  <div className="w-80 h-40 mt-4 flex flex-col p-2 items-center justify-center cursor-pointer 
					rounded-md border-dashed border border-black hover:border-primary"
                    onClick={() => {
                      if (props.user.id) {
                        setShowForm(true);
                      } else {
                        history.push('/login');
                      }
                    }}>
                    <div className="add-icon self-center">
                      <AddIcon className="icon-add" />
                    </div>
                    <h3 className="self-center">Add New Address</h3>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      {showForm && <AddressForm setShowForm={setShowForm} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    addresses: state.addresses,
    user: state.user.user,
  }
}

export default connect(mapStateToProps, { getAddresses })(Adress)
