import React from 'react';
import './styles.css'

import PhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";

const PhoneNumberInput = ({
  value,
  setValue,
  setPhoneCount,
  containerStyle,
  disabled,
  inputProps,
  inputStyle }) => {
  return (
    <PhoneInput
      countryCodeEditable={false}
      inputStyle={{ ...inputStyle, borderRadius: 0, width: "100%" }}
      country={"in"}
      value={value}
      onChange={(value, country) => {
        if (country) {
          let count = country?.format.split(".").length - 1;
          setPhoneCount && setPhoneCount(count);
        }
        setValue && setValue(value)
      }}
      containerStyle={containerStyle ? containerStyle : null}
      defaultErrorMessage={"invalid number"}
      disabled={disabled && disabled}
    />

  )
}

export default PhoneNumberInput;