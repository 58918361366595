import React, { useEffect, useState } from 'react'
import '../../styles/cart.css'
import { history } from '../../history'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/Add';
import { checkout } from '../../actions/checkout'
import { notification } from '../../actions'
import SideCartItem from '../sideCart/SideCartItem'
import AddressCard from '../profile/AddressCard'
import AddressForm from '../profile/AddressForm'
import Msg from '../notification/Msg'
import Coupon from '../cart/Coupon'
import { EMPTY_CART } from '../../assets';
import { Link } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io'

const Cart = (props) => {
  const [showForm, setShowForm] = useState(false)
  const [showPromo, setShowPromo] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [couponDiscount, setCouponDiscount] = useState(0)
  const [Code, setCode] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(props.addresses[0])
  const [showMoreAddresses, setShowMoreAddresses] = useState(false);

  const getCodeNDiscount = (couponDiscount, code) => {
    setCouponDiscount(couponDiscount)
    setCode(code)
    // console.log(couponDiscount, code)
    console.log(couponDiscount, Code)
  }

  const getActiveAdd = (addIndex, selecAdd) => {
    setActiveIndex(addIndex)
    setSelectedAddress(selecAdd)
  }

  useEffect(() => {
    if (props.addresses.length == 1) {
      setSelectedAddress(props.addresses[0]);
    }
    // setSelectedAddress(props.addresses[props.addresses.length - 1]);
  }, [props.addresses]);

  const handleCheckout = () => {
    // if(props.addresses.length == 1) {
    // 	setSelectedAddress(props.addresses[0]);
    // }
    // console.log(props.user.id)
    // console.log(selectedAddress);
    if (props.cartItems.length <= 0) {
      props.notification({ msg: "No item in the cart.", err: true });
    } else {
      if (props.user.id) {
        if (props.addresses.length <= 0) {
          props.notification({ msg: "No address available.", err: true });
        }
        else {
          props.checkout({
            address: selectedAddress,
            orderType: 'Paid Online',
            couponDiscount,
            Code
          })
        }
      } else {
        history.push('/login')
      }
    }
  }
  if (props.cartItems.length === 0) return (
    <div className="w-full h-full pb-12 md:pb-6 flex flex-col items-start p-4">
      <div className="relative w-full xl:w-[90%] max-w-screen-2xl md:mx-auto h-full flex flex-col items-start justify-center">
        <div className='w-full flex justify-between items-center my-5'>
          <h1 className="text-2xl md:text-4xl font-medium mb-4">
            Cart
          </h1>
          <IoMdClose className='text-3xl cursor-pointer' onClick={() => history.goBack()} />
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center text-xl md:text-3xl font-medium text-gray-600  text-center ">
          <img src={EMPTY_CART} alt="empty_shopping-bag" className="w-56" />
          <h1 className="my-5">Your cart is empty</h1>
          <Link to="/">
            <button className="px-5 py-2 bg-primary text-white text-base  capitalize">GO SHOPPING</button>
          </Link>
        </div>
      </div>
    </div>
  )
  return (
    <>
      <div className="w-full h-full pb-12 md:pb-6 flex flex-col items-start p-4">

        <div className="relative w-full xl:w-[90%] max-w-screen-2xl md:mx-auto h-full flex flex-col items-start justify-center">
          <div className='w-full flex justify-between items-center my-5'>
            <h1 className="text-2xl md:text-4xl font-medium mb-4">
              Cart
            </h1>
            <IoMdClose className='text-3xl cursor-pointer' onClick={() => history.goBack()} />
          </div>
          <div className='w-full flex flex-col md:flex-row'>
            <div className="sticky-scroll-container">
              <h1 className="w-full text-lg md:text-2xl font-semibold p-0 flex items-center">
                {props.cartItems.length} Items{" "}
                (Total:&nbsp; {props.cartTotal})
              </h1>
              <div className="w-full h-full flex flex-col items-start justify-start">
                {props.cartItems.length > 0 && props.cartItems?.map((item) => (
                  <SideCartItem key={item.product.id}
                    product={item.product}
                    quantity={item.quantity}
                  />
                ))}
              </div>
            </div>
            <div className="w-full h-full  min-h-[600px] md:w-3/5 md:ml-auto md:pl-10 md:pr-4 flex flex-col items-start">
              <div className="w-full flex justify-between items-center">
                <h1 className="text-lg font-semibold m-0 p-0">Select Address</h1>
                {props.addresses.length > 0 && (
                  <div className="w-10 h-10 p-2 hover:bg-gray-100 rounded-full cursor-pointer" onClick={() => props.user.id ? setShowForm(true) : history.push("/login")}>
                    <AddIcon className="w-full h-full" />
                  </div>
                )}
              </div>
              <div className="w-full p-0 flex flex-col">
                {props.addresses.length > 0 ?
                  <>
                    <div className="flex items-center flex-wrap">
                      {props.addresses.slice(0, 2).map((address, i) => (
                        <div className="sm:mr-8">
                          <AddressCard
                            key={address.id}
                            getActiveAdd={getActiveAdd}
                            address={address}
                            i={address.id}
                            style={
                              address.id === activeIndex ? { border: "2px solid #000" } : null
                            }
                          />
                        </div>
                      ))
                      }
                    </div>
                    {showMoreAddresses &&
                      <div className="flex items-center flex-wrap">
                        {props.addresses.slice(2).map((address, i) => (
                          <div className="sm:mr-8">
                            <AddressCard
                              key={address.id}
                              getActiveAdd={getActiveAdd}
                              address={address}
                              i={address.id}
                              style={
                                address.id === activeIndex ? { border: "2px solid #000" } : null
                              }
                            />
                          </div>
                        ))
                        }
                      </div>
                    }
                  </>
                  :
                  <div className="w-80 h-40 mt-4 flex flex-col p-2 items-center justify-center cursor-pointer 
										rounded-md border-dashed border border-black hover:border-primary hover:scale-110"
                    onClick={() => {
                      if (props.user.id) {
                        setShowForm(true);
                      } else {
                        history.push('/login');
                      }
                    }}>
                    <div className="add-icon self-center">
                      <AddIcon className="icon-add" />
                    </div>
                    <h3 className="self-center">Add New Address</h3>
                  </div>
                }
              </div>
              {props.addresses.length > 2 &&
                <div className="w-full h-5 flex items-center justify-center my-2">
                  <div className="h-[2px] bg-gray-200 w-full"></div>
                  <div className="text-center h-full bg-white text-xs cursor-pointer whitespace-nowrap px-2"
                    onClick={() => setShowMoreAddresses(!showMoreAddresses)}>{showMoreAddresses ? 'Show less' : 'Show More'}</div>
                  <div className="h-[2px] bg-gray-200 w-full"></div>
                </div>
              }
              <div className="fixed bottom-0 right-0 z-30 md:right-24 md:bottom-8 flex mt-auto w-full items-center justify-end">
                <button className="bg-primary text-white w-full md:w-32 h-12 shadow-md" onClick={handleCheckout}>Proceed</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showForm && <AddressForm setShowForm={setShowForm} />}
      {showPromo && <Coupon getCodeNDiscount={getCodeNDiscount} setShowPromo={setShowPromo} />}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    cartItems: state.cart,
    cartTotal: state.cartTotal.total,
    addresses: state.addresses,
  }
}

export default connect(mapStateToProps, { checkout, notification })(Cart)
