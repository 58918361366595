import React, { useEffect, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export function useDrag() {
  const [clicked, setClicked] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const position = React.useRef(0);

  const dragStart = React.useCallback((ev) => {
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStop = React.useCallback(
    () =>
      // NOTE: need some delay so item under cursor won't be clicked
      window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    []
  );

  const dragMove = (ev, cb) => {
    const newDiff = position.current - ev.clientX;
    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging,
  };
}

export function useTouchDrag() {
  const [clicked, setClicked] = React.useState(false);
  const [touching, setTouching] = React.useState(false);
  const position = React.useRef(0);

  const touchDragStart = React.useCallback((ev) => {
    position.current = ev.touches[0].clientX;
    setClicked(true);
  }, []);

  const touchDragStop = React.useCallback(
    () =>
      // NOTE: need some delay so item under cursor won't be clicked
      window.requestAnimationFrame(() => {
        setTouching(false);
        setClicked(false);
      }),
    []
  );

  const touchDragMove = (
    ev,
    cb
  ) => {
    const newDiff = position.current - ev.touches[0].clientX;
    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setTouching(true);
    }

    if (touching && movedEnough) {
      position.current = ev.touches[0].clientX;
      cb(newDiff);
    }
  };

  return {
    touchDragStart,
    touchDragStop,
    touchDragMove,
    touching,
    position,
    setTouching,
  };
}




export const useClickOutside = (elRef, callback) => {
  const callbackRef = useRef(null);
  callbackRef.current = callback;
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (elRef.current && !elRef.current.contains(e.target)) {
        // @ts-ignore
        callbackRef.current(e);
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
  }, [elRef, callback]);

}