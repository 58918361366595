import React from "react";
import { WATSAPP } from "../assets";

const WhatsappConnect = () => {
  return (
    <div className="bg-transperent width-auto h-auto flex justify-center items-end m-0 fixed bottom-10  right-0 z-40 transition-all duration-200 ease-in-out">
      <div className="bg-white h-16 p-5 w-auto flex justify-center items-center border-[1px] border-primary-500">
        <a href="https://wa.me/919108784301" target="_blank">
          <img src={WATSAPP} alt="" className="bg-contain" />
        </a>
      </div>
    </div >
  );
};

export default WhatsappConnect;
