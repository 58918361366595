import React, { useEffect, useState } from 'react'
import ProfileNavigation from '../profile/ProfileNavigation'
import { connect } from 'react-redux'
import '../../styles/wishlist.css';
import { PAINTING2 } from '../../assets';
import PaintingCard from '../cards/PaintingCard';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import ProfileHeader from '../profile/ProfileHeader';

const WhisList = (props) => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    setProducts(props.wishlist);
  }, [props.wishlist]);

  return (
    <div className="w-full h-full mt-5 flex flex-col md:flex-row items-start justify-center">
      <div className="relative w-full md:w-[90%] max-w-screen-2xl mx-auto p-2 px-4 h-full flex flex-col items-start justify-center">
        <ProfileHeader ></ProfileHeader>
        <div className='w-full flex items-start justify-center'>
          <div className="hidden md:flex sticky-scroll-container">
            <ProfileNavigation />
          </div>
          <div className="w-full md:w-3/5 md:pl-10 md:ml-auto flex flex-col justify-center ml-auto ">
            <h1 className="text-primary flex items-center justify-start mb-3 md:mb-0 md:hidden text-2xl font-medium">
              <span className="pr-2"><Link replace to={'/profile-and-details'}><KeyboardBackspaceIcon /></Link></span>
              Wishlist</h1>
            {products.length > 0 ?
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                {
                  products.map((pro) => (
                    <div className="" key={pro.id}>
                      <PaintingCard product={pro} key={pro.id} />
                    </div>
                  ))
                }

              </div>
              :
              <h1 className="h-64 flex items-center justify-center text-gray-400 text-2xl">No items in your wishlist</h1>
            }
          </div>
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user?.user,
    wishlist: state.wishlist
  }
}

export default connect(mapStateToProps)(WhisList)
