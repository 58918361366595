import React, { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { Link } from 'react-router-dom';

const MenuDropDown = ({ options, title, to, setActive, setShowSideBar, active, uppercase, id }) => {
  const [showOptions, setShowOptions] = useState(false);


  function chunk(array, size = 1) {
    let arrayChunks = [];
    for (let i = 0; i < array.length; i += size) {
      let arrayChunk = array.slice(i, i + size);
      arrayChunks.push(arrayChunk);
    }
    return arrayChunks
  }
  const newOptions = chunk(options, 8);

  return (
    <div className="w-full md:w-auto md:h-full flex flex-col items-start md:items-center justify-center relative select-none pr-3 md:px-0" >
      <div className="flex h-full md:h-full w-full justify-center">
        <div className="group h-full flex md:justify-center md:items-center">
          <li className={`text-sm w-full whitespace-pre-wrap md:whitespace-nowrap md:items-center`} >
            {to ?
              <Link id={id} to={to} className={`${uppercase && "uppercase"} h-full py-2 hover:text-[#E5AC74]`}>
                <span onClick={() => { setShowSideBar(false); }} >{title}</span>
              </Link >
              :
              <span className='text-sm uppercase h-full py-2 cursor-pointer hover:text-[#E5AC74]' onClick={() => { setShowSideBar(false); }} >{title}</span>
            }
            <div className={`${showOptions ? "flex" : "hidden group-hover:flex"} md:absolute transform md:-translate-x-1/2 left-1/2 top-full
            w-full md:w-max flex-col mt-1 border-t md:border md:shadow-md transition-all
          max-h-[500px] flex-wrap
             md:max-h-min overflow-y-scroll bg-white
            `}>
              <div className='w-fit max-h-[500px]'>
                {newOptions.map((options) =>
                  <div className='inline-block align-top'>
                    {options.map((item) =>
                      <div className='p-2 w-full text-sm hover:bg-gray-100 bg-white text-left md:text-left' key={item.name}>
                        {to ?
                          <Link id={item.id} to={`/category/${title}/${item.name}`} className="w-full hover:text-black">
                            <span className='hover:text-[#E5AC74]' onClick={() => setShowSideBar(false)}>{item.name}</span>
                          </Link >
                          :
                          <Link id={item.id} to={`/${item.name}`} className="w-full p-2">
                            <span className='hover:text-[#E5AC74]' onClick={() => setShowSideBar(false)}>{item.name}</span>
                          </Link >
                        }
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </li>
        </div>
        <MdArrowDropDown className="block text-xl ml-auto md:hidden shrink-0 my-3" onClick={() => setShowOptions(!showOptions)} />
      </div >
    </div >

  )
}

export default MenuDropDown;