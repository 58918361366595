import React from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { logout } from '../../actions/index'
import { connect } from 'react-redux'
import ProfileHeader from './ProfileHeader';
import { IoMdArrowDropdown, IoMdClose } from 'react-icons/io'

const ProfileNavigation = (props) => {
  const history = useHistory();
  const path = history.location.pathname;

  const handleLogout = () => {
    props.logout()
  }

  return (
    <>
      {/* {path === "/profile-and-details" && <Header />} */}
      <div
        className={`px-4 md:p-0 md:mt-0`}>
        <div className='flex w-full md:hidden justify-between items-center my-5'>
          <h1 className="text-2xl md:text-4xl font-medium mb-4">
            Profile
          </h1>
          <IoMdClose className='text-2xl cursor-pointer' onClick={() => history.goBack()} />
        </div>
        <div className="w-full md:w-11/12 md:px-0">
          <Link to="/profile" replace>
            <p className={`${path === "/profile" && "bg-primary text-white cursor-pointer"} p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer`}>
              Profile Details
            </p>
          </Link>
          <Link to="/profile/address" replace>
            <p className={`${path === "/profile/address" && "bg-primary text-white cursor-pointer"} p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer`}>
              My Address
            </p>
          </Link>
          <Link to="/profile/orders" replace>
            <p className={`${path === "/profile/orders" && "bg-primary text-white cursor-pointer"} p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer`}>
              My Order
            </p>
          </Link>
          <Link to="/profile/whislist" replace>
            <p className={`${path === "/profile/whislist" && "bg-primary text-white cursor-pointer"} p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer`}>
              Wish List
            </p>
          </Link>
        </div>
        <p className="font-bold text-gray-600 text-sm pt-6">
          POLICY AND COOKIES
        </p>
        <div className="nav bottom">
          <Link to="/privacy-policy">
            <p className="p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer">Privacy Policy</p>
          </Link>
          <Link to="/terms&conditions">
            <p className="p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer">Terms & conditions</p>
          </Link>
          <Link to="/return-policy">
            <p className="p-5 w-full flex items-center justify-between py-7 border-b cursor-pointer">Returns</p>
          </Link>
          <p className=" p-5 w-full flex items-center justify-between py-7 border-b  cursor-pointer" onClick={handleLogout}>
            Logout
          </p>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  return { user: state.user?.user }
}
export default connect(mapStateToProps, { logout })(ProfileNavigation)
