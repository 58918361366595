import React, { useEffect, useState } from 'react';
import { db, firestore } from '../../firebase';
import { useParams } from 'react-router-dom'
import SubCatProducts from '../SubCategoryCards';
import LoadingSpinner from '../LoadingSpinner';

const Category = () => {
  const categoryName = useParams().category;
  const [categoryInfo, setCategoryInfo] = useState({});
  const [subCats, setSubCats] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setCategoryInfo({});
    // setSubCats([]);
    firestore.collection('CATAGORIES').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log(doc.data().name, categoryName);
        if (doc.data().name === categoryName) {
          setCategoryInfo(db.formatedDoc(doc));
          return;
        }
      })
      return;
    })
  }, [categoryName]);

  // get sub-cats
  useEffect(() => {
    setLoading(true);
    let items = [];
    firestore.collection('SUB-CATAGORIES').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let x = doc.data();
        if (x.category.name === categoryName) {
          items.push(x);
        }
      })
      setSubCats(items);
      setLoading(false);
    })
  }, [categoryInfo]);
  // console.log(categoryInfo)
  // console.log(subCats);

  return (
    <>
      <div className="w-full flex flex-col mt-20 md:mt-36">
        <div className="w-full   flex items-center justify-center" style={{ height: '512px' }}>
          {/* <h1 className="text-white text-2xl md:text-5xl uppercase">{categoryInfo.name}</h1> */}
          <img src={categoryInfo.picUrl} className="object-contain w-full h-full" />
        </div>
        {subCats.length > 0 ? subCats.map((item) => (
          <SubCatProducts subcat={item} key={item.name} />
        ))
          :
          loading ?
            <LoadingSpinner />
            : subCats.length <= 0 ?
              <h1 className="h-64 flex items-center justify-center text-gray-400 text-5xl">No items in this category</h1>
              : null}
      </div>
    </>
  )
}

export default Category;