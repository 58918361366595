export const SIGN_IN = "SIGN_IN"
export const OTP = "OTP"
export const AUTH_STATE = "AUTH_STATE"
export const LOG_OUT = "LOG_OUT"
export const MSG = "MSG"
export const NOT_SINGUPED = "NOT_SINGUPED"
export const SHOW_OTP = "SHOW_OTP"
export const ADD_ADDRES = "ADD_ADDRES"
export const GET_ADDRESS = "GET_ADDRESS"
export const ADD_SINGLE_ADDRESS = "ADD_SINGLE_ADDRESS"
export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const UPDATE_ADDRESS = "UPDATE_ADDRESS"
export const ADD_TO_WHISLIST = "ADD_TO_WHISLIST"
export const GET_WISHLIST = "GET_WISHLIST"
export const DELETE_WISHLIST = "DELETE_WISHLIST"
export const ADD_REVIEW = "ADD_REVIEW"
export const GET_REVIEWS = "GET_REVIEWS"
export const ADD_TO_CART = "ADD_TO_CART"
export const GET_CART = "GET_CART"
export const SHOW_CART = "SHOW_CART"
export const CART_TOTAL = "CART_TOTAL"
export const DELETE_CART_ITEM = "DELETE_CART_ITEM"
export const UPDATE_CART_QUANITY = "UPDATE_CART_QUANITY"
export const CHECKOUT = "CHECKOUT"
export const PLACE_ORDER = "PLACE_ORDER"
export const GET_ORDERS = "GET_ORDERS"
export const CANCEL_ORDER = "CANCEL_ORDER"