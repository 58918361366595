import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CancleForm from '../orders/CancleForm'
import { RUPPEEICON } from '../../assets'
import ContactPopup from '../ContactPopup'

const OrderCard = (props) => {
  const [showCancleForm, setShowCancleForm] = useState(false)
  const [showContactSupport, setShowContactSupport] = useState(false);
  const [cancel, setCancel] = useState(true);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatDate = (ms) => {
    let fdate = new Date(ms);
    let date = fdate.getDate();
    let month = fdate.getMonth();
    let year = fdate.getFullYear();
    return `${date} ${months[month]} ${year}`;
  };

  const GetHoursPassed = (bookingTime) => {
    let c = Date.now();
    let x = c - bookingTime;
    x = x / 1000;
    if (x < 60) {
      return `${Math.round(x)} seconds ago`
      // setTimePassed(`${x} seconds ago`)
    } else if (x < 3600) {
      let minutes = Math.round(x / 60);
      return `${minutes} minutes ago`
    } else if (x > 3600) {
      let hours = Math.round(x / 3600);
      return `${hours} hours ago`
    }
  }
  useEffect(() => {
    if (Date.now() < props.order.bookingTime + 86400000) {
      setCancel(true);
    } else {
      setCancel(false);
    }
  }, []);

  return (
    <>
      <div className="bg-gray-100 px-4 py-2 sm:py-8">

        <div className="flex items-center">
          <h2 className="uppercase text-sm font-medium tracking-tight mr-5">{formatDate(props.order.bookingTime)}</h2>
          {Date.now() < props.order.bookingTime + 86400000 && <h2 className="text-sm text-gray-500 italic font-medium tracking-tight">{GetHoursPassed(props.order.bookingTime)}</h2>}
        </div>

        {props.order.items.map((item) => (
          <div className="flex w-full justify-start items-start my-4" key={item.product.id}>
            {/* <Link to={`/products/${item.product.id}`}> */}
              <div className="w-32 h-40 md:w-40 md:h-48">
                <img src={item.product.picUrl} alt="" />
              </div>
            {/* </Link> */}
            <div className="flex flex-col w-full h-full px-2">
              <div className="group w-full flex items-center justify-start mb-2">
                <h3 className="text-sm md:text-base font-medium text-gray-600">{item.product.name}</h3>
              </div>


              <div className="">
                <div className="flex items-center">
                  <img src={RUPPEEICON} className="w-4 h-4" alt="" />
                  {item.product?.discountedMrp !== item.product?.mrp ?
                    <>
                      <h1 className="text-sm font-medium">{item.product?.discountedMrp}</h1>
                      <h1 className="text-sm font-medium line-through text-gray-400">{item.product?.mrp}</h1>
                    </>
                    :
                    <h1 className="text-sm font-medium">{item.product?.mrp}</h1>
                  }
                </div>
                {item.product?.discountPercentage ?
                  <p className="inline-block text-xs text-green-400">Discount Applied : {item.product?.discountPercentage}% off</p>
                  : item.product?.flatDiscountAmount ?
                    <p className="inline-block text-xs text-green-400">flat discount amount : {item.product?.flatDiscountAmount} off</p>
                    : null
                }
              </div>
              {props.order.state === 'newOrder' && <p className="text-sm font-medium text-green-600 mb-2">PLACED</p>}
              {props.order.state === 'inPacking' && <p className="text-sm font-medium text-green-600 mb-2">PACKED</p>}
              {props.order.state === 'readyForDelivery' && <p className="text-sm font-medium text-green-600 mb-2">Ready</p>}
              {props.order.state === 'delivered' && <p className="text-sm font-medium text-green-600 mb-2">DELIVERED</p>}
              {props.order.state === 'canceled' && <p className="text-sm font-medium text-red-600 mb-2">CANCELED</p>}
              <div className="order-btns">
                <p className="text-xs pt-2 underline cursor-pointer" onClick={() => setShowContactSupport(true)} > CONTACT SUPPORT</p>
              </div>

            </div>
            {showContactSupport && <ContactPopup setShowContactSupport={setShowContactSupport} />}
            {showCancleForm && <CancleForm order={props.order} item={item} setShowCancleForm={setShowCancleForm} />}
          </div>
        ))}


        <div className="w-full flex flex-col">
          <div className="ml-auto flex flex-col items-end">
            <p className="text-sm text-gray-700 font-normal flex items-center text-right">Item total:&nbsp; {props.order.totalMrp - props.order.deliveryCharge}</p>
            <p className="text-sm text-gray-700 font-normal flex items-center text-right">Delivery Charges:&nbsp; {props.order.deliveryCharge}</p>
            <p className="text-gray-900 font-semibold flex items-center text-right">Grand Total:&nbsp; {props.order.totalMrp}</p>
          </div>
        </div>
      </div>

    </>
  )
}

export default OrderCard
