import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import TextField from '@material-ui/core/TextField';
import { useState } from "react";
import PhoneNumberInput from './PhoneNumberInput/PhoneNumberInput';
import Loader from "react-loader-spinner";
import { firestore } from "../firebase";
import { connect, useDispatch } from "react-redux";
import { notification } from '../actions'
import { toast } from "react-toastify";


const ContactPopup = ({ setShowContactSupport }) => {
  const { handleSubmit, register, formState: { errors }, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [phoneCount, setPhoneCount] = useState(null);
  const [isNum, setIsNum] = useState(true);
  const [number, setNumber] = useState("");

  const onSubmit = (data) => {
    if (phoneCount !== number.length) {
      setIsNum(false);
      return
    }
    setLoading(true);
    const ref = firestore.collection('ENQUIRIES').doc();
    ref.set({
      id: ref.id,
      name: data.name,
      email: data.email,
      phoneNumber: `+${number}`,
      reason: data.query,
    }).then(() => {
      setNumber('+91')
      toast.success('Form Submitted', { theme: 'dark' });
      setLoading(false);
      setShowContactSupport(false);
      reset({
        name: "",
        email: "",
        query: ""
      })
    }).catch(err => {
      console.log(err);
      toast.error('Something went wrong', { theme: 'dark' });
    })
  }

  return (
    <div className="z-50 fixed top-0 left-0 bg-black/50 w-full h-screen overflow-hidden flex items-center justify-center">
      <div className="w-[90%] md:w-2/5 bg-white flex flex-col p-7 px-5 md:px-10">
        <div className="flex justify-between items-center my-3">
          <h1 className="text-2xl font-semibold text-primary">Contact Support</h1>
          <MdClose className="text-xl cursor-pointer" onClick={() => setShowContactSupport(false)} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full flex flex-col items-start">
          <div className="my-3 w-full">
            <p className="text-sm mb-1">Name</p>
            <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="text" placeholder="Name" {...register("name", { required: true, min: 3, maxLength: 80 })} />
            {errors.name && <span className="text-xs text-red-400">This field is required</span>}
          </div>
          <div className="my-3 w-full">
            <p className="text-sm mb-1">Email</p>
            <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="email" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
            {errors.email && <span className="text-xs text-red-400">This field is required</span>}
          </div>
          <div className="my-3 w-full">
            <p className="text-sm mb-1">Phone Number</p>
            <PhoneNumberInput
              value={number}
              inputStyle={{ fontSize: 14 }}
              containerStyle={{ margin: 0 }}
              setPhoneCount={setPhoneCount}
              setValue={setNumber}
            />
            {!isNum && <p className="text-xs text-red-400">Number is required</p>}
          </div>

          <div className="my-3 w-full">
            <p className="text-sm mb-1">Contact Reason</p>
            <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="text" placeholder="Reason" {...register("query", { required: true })} />
            {errors.query && <span className="text-xs text-red-400">This field is required</span>}
          </div>
          <button type="submit" className="self-end  bg-primary text-sm text-white w-44 h-11 mt-5 text-center flex items-center justify-center">{loading ?
            <Loader type="TailSpin" color="#fff" height={15} width={15} />
            :
            'Submit'
          }</button>
        </form>


      </div>
    </div>
  )
}


export default ContactPopup;
