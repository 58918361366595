import {  IoMdClose } from 'react-icons/io'
import { history } from '../../history';


const ProfileHeader = () => {
  return (
    <div className='hidden w-full md:flex justify-between items-center my-5'>
      <h1 className="text-2xl md:text-4xl font-medium mb-4">
        Profile
      </h1>
      <IoMdClose className='text-3xl cursor-pointer' onClick={() => history.goBack()} />
    </div>
  )
}

export default ProfileHeader;