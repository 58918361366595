import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux'
import { updateAddress } from '../../actions/address'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import '../../styles/addressform.css'
import countryDataQuery from 'country-data-query';

const EditAddressForm = (props) => {
  const [country, setCountry] = useState(props.country)
  const [addressType, setAddressType] = useState(props.addressType)
  const [pinCode, setPinCode] = useState(props.pinCode)
  const [addressLine1, setAddressLine1] = useState(props.addressLine1)
  const [addressLine2, setAddressLine2] = useState(props.addressLine2)
  const [landmark, setLanmark] = useState(props.landmark)
  const [city, setCity] = useState(props.city)
  const [name, setName] = useState(props.name)
  const [number, setNumber] = useState(props.number)
  const [showFirstForm, setShowFirstForm] = useState(true)
  const [state, setState] = useState(props.state)
  const [phoneCount, setPhoneCount] = useState(0);
  const [isNum, setIsNum] = useState(true)
  const options = countryDataQuery({ country: {} }).map((i) => i.name)

  const [states, setStates] = useState([]);

  useEffect(() => {
    if (country) {
      const code = countryDataQuery({ country: {} }).find((i) => i.name === country)?.code

      const countryStates = countryDataQuery({
        country: { type: "countrycode", value: code },
        region: {}
      })
      setStates(countryStates.map((i) => i.name));
    }
  }, [country]);


  const submitForm1 = (e) => {
    e.preventDefault()
    if (phoneCount != number.length) {
      setIsNum(false);
    } else {
      setShowFirstForm(false)
    }
  }
  const submitAddress = (e) => {
    e.preventDefault()
    props.updateAddress(props.id, { name, number, country, state, addressType, pinCode, addressLine1, addressLine2, landmark, city })
    setShowFirstForm(true)
    props.setShowForm(false)
  }

  return (
    <div className="add-full" onClick={() => props.setShowForm(false)}>
      {showFirstForm && (<form onSubmit={submitForm1} onClick={(e) => e.stopPropagation()} >
        <h1>Add New Address</h1>
        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="FULL NAME"
          variant="outlined"
          placeholder="Full Name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required

        />
        {/* <TextField
          autoComplete="off"
          id="outlined-basic"
          label="MOBILE NUMBER"
          variant="outlined"
          placeholder="Mobile Number"
          onChange={(e) => setNumber(e.target.value)}
          value={number}
          required
          type="number"

        /> */}

        <PhoneNumberInput value={number} setValue={setNumber} setPhoneCount={setPhoneCount} />
        {!isNum && <p className="text-red-500">Number is required</p>}

        <Autocomplete
          autoComplete={false}
          value={country}
          onChange={(
            event,
            newValue
          ) => {
            setCountry(newValue);
          }}
          id="controllable-states-demo"
          options={options}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Country"
              variant="outlined"
            />
          )}
        />

        <Autocomplete
          autoComplete={false}
          id="combo-box-demo"
          value={state}
          onChange={(
            event,
            newValue
          ) => {
            setState(newValue);
          }}
          options={states}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="State"
              variant="outlined"
            />
          )}
        />

        <button className="bg-primary text-white w-32 h-12 shadow-md self-end mt-5" type="submit" >
          NEXT</button>
      </form>)}


      {!showFirstForm && (<form onSubmit={submitAddress} onClick={(e) => e.stopPropagation()} className="nextForm" >
        <h1>Add New Address</h1>
        <FormControl variant="outlined" required>
          <InputLabel id="demo-simple-select-outlined-label">ADDRESS TYPE</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={addressType}
            onChange={(e) => setAddressType(e.target.value)}
            label="Age"
          >
            <option selected ria-label="None" value="">
              Select
            </option>
            <option selected={true} value={'Home'}>Home</option>
            <option value={'Office'}>Office</option>
            <option value={'Store'}>Store</option>
          </Select>
        </FormControl>

        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="PIN CODE"
          variant="outlined"
          placeholder="PIN CODE"
          onChange={(e) => setPinCode(e.target.value)}
          value={pinCode}
          required
          type="number"
        />
        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="FLAT, HOUSE NO., BUILDING, COMPANY,"
          variant="outlined"
          onChange={(e) => setAddressLine1(e.target.value)}
          placeholder="FLAT, HOUSE, BUILDING, COMPANY"
          value={addressLine1}
          required
        />
        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="AREA, COLONY, STREET, SECTOR, VILLAGE"
          variant="outlined"
          placeholder="AREA, COLONY, STREET, SECTOR, VILLAGE"
          onChange={(e) => setAddressLine2(e.target.value)}
          value={addressLine2}
          required
        />
        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="LANDMARK"
          variant="outlined"
          placeholder="LANDMARK"
          value={landmark}
          onChange={(e) => setLanmark(e.target.value)}
          required
        />
        <TextField
          autoComplete="off"
          id="outlined-basic"
          label="TOWN/CITY"
          variant="outlined"
          placeholder="TOWN/CITY"
          onChange={(e) => setCity(e.target.value)}
          value={city}
          required
        />


        <button className="bg-primary text-white w-32 h-12 shadow-md self-end mt-5" type="submit" >
          UPDATE</button>
      </form>)}
    </div>
  )
}

export default connect(null, { updateAddress })(EditAddressForm)
