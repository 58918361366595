import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import { history } from '../../history'
import { firestore } from '../../firebase'
import { connect } from 'react-redux'
import { getCartItems } from '../../actions/cart'
import { placeOrder } from '../../actions/orders'
import InfoIcon from '@material-ui/icons/Info'
import SideCartItem from '../sideCart/SideCartItem'
import AddressCard from '../profile/AddressCard'
import { KALAVARANA_LOGO } from '../../assets'
import Checkbox from '@material-ui/core/Checkbox';
import { MdArrowBack } from "react-icons/md";

const Checkout = (props) => {
  console.log(props);
  const donation = 50;
  const [grandTotal, setGrandTotal] = useState((props.checkout.total + props.checkout.deliveryCharge) - props.checkout.couponDiscount);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // console.log("checked");
    if (checked) {
      setGrandTotal(grandTotal + donation);
    } else {
      setGrandTotal((props.checkout.total + props.checkout.deliveryCharge) - props.checkout.couponDiscount);
    }
  }, [checked]);

  // for offline testing purpose
  const handlePlaceOrder = () => {
    props.placeOrder({ ...props.checkout, grandTotal, paymentId: "#123", isPaymentDone: true, donation })
    history.push('/order-confirmed')
  }

  const loadScripts = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script);
    })
  }

  const options = {
    "key": "rzp_live_IQokpoxhzmIjtY", // Enter the Key ID generated from the Dashboard
    "currency": 'INR',
    "amount": grandTotal * 100,
    "name": "Kalavarana",
    "image": KALAVARANA_LOGO,
    "description": props.user.name,
    //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response) {
      props.placeOrder({ ...props.checkout, grandTotal, paymentId: response.razorpay_payment_id, isPaymentDone: true, donation })
      history.push('/order-confirmed')
      // console.log(response)
      const payRef = firestore.collection('PAYMENTS')
        .doc(props.user.email).set({
          razorpay_payment_id: response.razorpay_payment_id
        })
        .then(() => {
          console.log('saved sucessfully')
        })
    },
    "prefill": {
      "name": props.user.name,
      "email": props.user.email,
      "contact": props.user.phoneNumber
    },
  };

  const displayRazorpay = async () => {
    const res = await loadScripts('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      history.push('/Error')
    }
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
      history.push('/Error')
    });
    paymentObject.open();
  }

  return (
    <>
      {!props.user.id && <Redirect to='/login' />}
      <div className="w-full xl:w-[90%] mx-auto max-w-screen-2xl h-full py-5 p-4 flex flex-col items-start justify-start">
        <div className="w-full flex items-center">
          <span onClick={() => history.goBack()} className="">
            <MdArrowBack className="text-3xl mr-4 cursor-pointer" />
          </span>
          <h1 className="text-2xl md:text-3xl font-semibold text-primary">Checkout</h1>
        </div>
        <div className="relative mt-10 w-full mx-auto h-full flex flex-col md:flex-row items-start justify-start ">
          <div className="scroll-container flex flex-col">
            <h1 className="text-xl md:text-2xl font-semibold m-0 p-0">
              {props.cartItems.length} Items
            </h1>
            {/* side-cart-items */}
            <div className="w-full h-full flex flex-col items-start justify-start">
              {props.cartItems.length > 0 && props.cartItems?.map((item, i) => (
                <SideCartItem key={item.id}
                  product={item.product}
                  quantity={item.quantity}
                  disable />
              ))}
            </div>
          </div>
          <div className="w-full md:w-3/5 ml-auto pt-7 px-0 md:px-12 flex flex-col items-start justify-between">
            <h1 className="text-xl font-bold">Delivering to,</h1>
            <div className="w-full mt-2 md:mt-5 p-0">
              <AddressCard
                address={props.checkout.address}
                disable
              />
            </div>
            <div className="w-full h-full flex flex-col items-start justify-start mt-8">
              <h1 className="text-xl font-bold mb-1 ">Total Price</h1>
              <div className="flex flex-col items-start w-full md:w-1/2">
                <div className="w-full flex items-center justify-between py-3 border-b border-gray-500">
                  <p className='text-sm'>Item Total</p>
                  <p className='text-sm'>{props.total}</p>
                </div>
                <div className="w-full flex items-center justify-between py-3 border-b border-gray-500">
                  <p className='text-sm'>Delivery Charges</p>
                  <p className='text-sm'>{props.checkout.deliveryCharge}</p>
                </div>
                {props.checkout.couponDiscount > 0 &&
                  <div className="w-full flex items-center justify-between py-3 border-b border-gray-500">
                    <p className='text-sm'>Discount</p>
                    <p className='text-sm'>-{props.checkout.couponDiscount}</p>
                  </div>
                }
                <div className={`w-full flex items-center justify-between py-3 border-b border-gray-500
                 ${checked ? 'bg-gray-200' : 'bg-white'}`}>
                  <div className={`flex items-center -ml-2 `}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={checked}
                      onChange={() => {
                        setChecked(!checked);
                      }}
                      inputProps={{ 'aria-label': 'checkbox with small size' }}
                    />
                    <p className='text-sm'>I would like to donate</p>
                    {/* <Link to=""> */}
                    <div className="group relative">
                      <InfoIcon className="text-primary text-xs ml-2 cursor-pointer " style={{ width: '20px', height: '20px' }} />
                      <div className=" text-xs w-72 -ml-36 opacity-0 group-hover:opacity-100 bg-primary text-white p-1 rounded text-center absolute z-10 left-1/2 bottom-full">
                        This donation goes to one of the charity partners
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                  <p className='text-sm'>{donation}</p>
                </div>
                <div className="w-full flex items-center justify-between py-3 border-b border-gray-500">
                  <p className="font-bold">Grand Total</p>
                  <p className="font-bold">{grandTotal} Rs</p>
                </div>
                <p className="text-xs text-left mt-2" style={{ width: "100%" }}>
                  *All prices are inclusive of taxes
                </p>
              </div>
            </div>


            <div className="fixed bottom-0 right-0 z-30 md:right-24 md:bottom-8 flex mt-auto w-full items-center justify-end">
              <button className="bg-primary text-white w-full md:w-32 h-12 shadow-md" onClick={() => displayRazorpay()}>Pay Online</button>
              {/* <button className="bg-primary text-white w-full md:w-32 h-12 shadow-md" onClick={() => handlePlaceOrder()}>Pay</button> */}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    checkout: state.checkout?.checkout,
    total: state.cartTotal.total,
    user: state.user?.user
  }
}

export default connect(mapStateToProps, { placeOrder, getCartItems })(Checkout)
