import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import './inputstyle.css'
import '../../styles/auth.css'
import { connect } from 'react-redux'
import { sigin } from '../../actions'
import { COLLAGE, KALAVARANA_LOGO } from '../../assets'
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { history } from '../../history'
import { Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAppSelector } from '../../hooks'

const SignupComp = (props) => {
  const Rstate = useAppSelector((state) => state);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSignInSubmit = (data) => {
    props.sigin(Rstate.notSignedUser.phoneNumber, data.email, data.name, Rstate.notSignedUser.uid, history);
  };


  if (!Rstate.notSignedUser.phoneNumber) {
    return <Redirect to="/login" replace={true} />
  }
  // const [validate,setValidate]=useState({isName:true,isNumber:true,isEmail:true,hasOtp:true})
  // console.log(location.state);


  // const onSignInSubmit = (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   // setTimeout(() => {
  //   //   setIsLoading(false);
  //   // }, 10000);
  //   // check validation of email, name, number
  //   if (email === '' && name === '' && number === '') {
  //     setIsEmail(false)
  //     setIsName(false)
  //     setIsNumber(false)
  //   }

  //   validate('email', email);
  //   validate('name', name);
  //   validate('number', number);

  //   if (name && email && number && isName && isNumber && isEmail) {
  //     console.log("all good");
  //     setIsName(true)
  //     setIsNumber(true)
  //     setIsEmail(true)
  //     setIsLoading(true)
  //     props.sigin(number, email, name, location.state.uid)
  //   }
  // };


  return (
    <div className="flex flex-col md:flex-row items-center justify-center w-full h-full min-h-screen max-h-screen overflow-hidden" >
      <div className="w-full md:w-3/5 h-screen">
        <img src={COLLAGE} alt="loginImg" style={{ width: "100%", height: "100%" }} className="w-full h-full object-contain object-top md:object-cover md:object-left" />
      </div>
      <div className="w-full md:w-2/5 absolute md:static bg-white py-5 md:h-full bottom-0 bg-none backdrop-blur-sm
      flex flex-col items-center justify-evenly text-sm rounded  px-12">
        <form
          onSubmit={handleSubmit(onSignInSubmit)}
          className="flex flex-col text-sm w-full"
        >
          <div className="w-full flex justify-center md:justify-start items-center mx-auto md:mx-0">
            <img
              src={KALAVARANA_LOGO}
              alt="ANA"
              className="md:-ml-5"
              style={{ width: "112px", height: "39px" }}
            />
          </div>
          <h1 className="text-2xl font-bold flex items-center py-10 ">
            <AiOutlineArrowLeft className="text-xl mr-4" onClick={() => history.goBack()} />
            Just a small step,
          </h1>

          <div className="my-3 w-full max-w-sm">
            <p className="text-sm mb-1">Name</p>
            <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="text" placeholder="Name" {...register("name", { required: true, min: 3, maxLength: 80 })} />
            {errors.name && <span className="text-xs text-red-400">This field is required</span>}
          </div>

          <div className="my-3 w-full max-w-sm">
            <p className="text-sm mb-1">Email</p>
            <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="email" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
            {errors.email && <span className="text-xs text-red-400">This field is required</span>}
          </div>

          <div className="my-3 w-full max-w-sm">
            {/* <p className="text-sm mb-1">Phone Number</p> */}
            <PhoneNumberInput
              value={Rstate.notSignedUser.phoneNumber}
              containerStyle={{ margin: 0 }}
              disabled={true}
            />
          </div>
          <button type="submit"
            className="w-full md:w-32 flex justify-center items-center py-3 px-3 my-2 text-white mt-8 bg-primary">
            Proceed
          </button>
          <div id="recaptcha-container"></div>
        </form>
      </div>
    </div >
  )
}
const mapStateToProps = (state) => {
  return { user: state?.user?.user, mobNo: state.mobNo.mobNo }
}
export default connect(mapStateToProps, { sigin })(SignupComp)
