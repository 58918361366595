import React, { useState } from 'react'
import '../../styles/connect.css'
import { CHAT, PHONEICON, watsapp, CONNECT, WATSAPP } from '../../assets'
import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Home from '@material-ui/icons/Store';
import Accordain from '../Accordian'
import { firestore } from '../../firebase'
import Msg from '../notification/Msg'
import { connect } from 'react-redux'
import { notification } from '../../actions'
import { history } from '../../history';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";

const Enquiry = (props) => {
  const { handleSubmit, register, formState: { errors }, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [phoneCount, setPhoneCount] = useState(null);
  const [isNum, setIsNum] = useState(true);
  const [number, setNumber] = useState("");


  const onSubmit = (data) => {
    if (phoneCount !== number.length) {
      setIsNum(false);
      return
    }
    setLoading(true);
    const ref = firestore.collection('ENQUIRIES').doc();
    ref.set({
      id: ref.id,
      name: data.name,
      email: data.email,
      phoneNumber: `+${number}`,
      reason: data.query,
    }).then(() => {
      setNumber('+91')
      toast.success('Form Submitted', { theme: 'dark' });
      setLoading(false);
      reset({
        name: "",
        email: "",
        query: ""
      })
    }).catch(err => {
      console.log(err);
      toast.error('Something went wrong', { theme: 'dark' });
    })
  }
  return (
    <>
      <div className="w-full max-w-screen-2xl mx-auto my-20 md:my-36 flex items-center justify-center">
        <div className="w-full p-4 md:w-4/5 flex flex-col items-center justify-between">

          <div className="w-full flex flex-col items-center md:flex-row md:items-center md:justify-between mx-auto">
            <div className="h-auto relative w-full md:w-1/2 flex items-center justify-center">
              <img src={CONNECT} alt="" className="w-full object-cover object-center" />
              <h1 className="absolute top-10 left-5 w-11/12 p-2 text-center text-3xl font-medium">Lets Connect</h1>
            </div>
            <div className=" w-full md:w-1/2 h-full max-w-lg flex flex-col items-start justify-center mt-5 md:mt-0 p-4 md:p-10 border border-primary">

              <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full flex flex-col items-start">
                <div className="my-3 w-full">
                  <p className="text-sm mb-1">Name</p>
                  <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="text" placeholder="Name" {...register("name", { required: true, min: 3, maxLength: 80 })} />
                  {errors.name && <span className="text-xs text-red-400">This field is required</span>}
                </div>
                <div className="my-3 w-full">
                  <p className="text-sm mb-1">Email</p>
                  <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="email" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                  {errors.email && <span className="text-xs text-red-400">This field is required</span>}
                </div>
                <div className="my-3 w-full">
                  <p className="text-sm mb-1">Phone Number</p>
                  <PhoneNumberInput
                    value={number}
                    inputStyle={{ fontSize: 14 }}
                    containerStyle={{ margin: 0 }}
                    setPhoneCount={setPhoneCount}
                    setValue={setNumber}
                  />
                  {!isNum && <p className="text-xs text-red-400">Number is required</p>}
                </div>

                <div className="my-3 w-full">
                  <p className="text-sm mb-1">Contact Reason</p>
                  <input className=" w-full px-3 py-4 border border-[#cacaca] outline-none text-sm" type="text" placeholder="Reason" {...register("query", { required: true })} />
                  {errors.query && <span className="text-xs text-red-400">This field is required</span>}
                </div>
                <button type="submit" className="self-end  bg-primary text-sm text-white w-44 h-11 mt-3 text-center flex items-center justify-center">{loading ?
                  <Loader type="TailSpin" color="#fff" height={15} width={15} />
                  :
                  'Submit'
                }</button>
              </form>

            </div>
          </div>
          <div className="faq-section">
            <div className="faq-title">
              <h1 className="text-3xl font-bold">Support & FAQ</h1>
              <div className="line"></div>
            </div>
            <div className="connect-detail">
              <img src={CHAT} alt="" />
              <div className="detail-des">
                <h1 className="text-2xl py-6 font-bold">We are always here to help you</h1>
                <p>If you face any problem with our service feel free to contact us directly</p>
                <div className="email">
                  <MailOutlineIcon />
                  <a href="mailto:thekalavarana@gmail.com"><p>thekalavarana@gmail.com</p></a>
                </div>
                <div className="mobile">
                  <img src={PHONEICON} alt="" />
                  <a href="tel:9108784301"><p>+91 9108784301</p></a>
                </div>
                <div className="email">
                  <Home />
                  <p>Kalavarana,#147,tgr argade,Bethel nagar layout,<br />kodigehalli main road,K.r.puram, bangalore 36</p>
                </div>
                <a href="https://wa.me/919108784301" target="_blank"> <button> <img src={WATSAPP} alt="" /> WhatsApp us</button></a>
              </div>
            </div>
          </div>
          <div className="faq-acc">
            <h1 className="text-3xl font-bold">FAQs</h1>
            <div className="acc-area">
              <Accordain
                title="Lorem ipsum dolor sit amet?"
                accItems={['Your friend gets cashback worth 100 on placing their first successful order']}
              />
              <Accordain
                title="Lorem ipsum dolor sit amet?"
                accItems={['Your friend gets cashback worth 100 on placing their first successful order']}
              />
              <Accordain
                title="Lorem ipsum dolor sit amet?"
                accItems={['Your friend gets cashback worth 100 on placing their first successful order']}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return { user: state?.user?.user };
}

export default connect(mapStateToProps, { notification })(Enquiry);
