import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux'
import { updateAddress, getAddresses, addAdress } from '../../actions/address'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import '../../styles/addressform.css'
import countryDataQuery from 'country-data-query';
import { IoMdClose } from "react-icons/io";


const AddressForm = ({ setShowForm, addAdress }) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState('+91');
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [landmark, setLanmark] = useState("");
  const [city, setCity] = useState("");
  const [showFirstForm, setShowFirstForm] = useState(true);
  const [phoneCount, setPhoneCount] = useState(0);
  const [isNum, setIsNum] = useState(true);

  const options = countryDataQuery({ country: {} }).map((i) => i.name)
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (country) {
      const code = countryDataQuery({ country: {} }).find((i) => i.name === country)?.code

      const countryStates = countryDataQuery({
        country: { type: "countrycode", value: code },
        region: {}
      })
      setStates(countryStates.map((i) => i.name));
    }
  }, [country]);

  const submitForm1 = (e) => {
    e.preventDefault();
    if (phoneCount !== number.length) {
      setIsNum(false);
    } else {
      setShowFirstForm(false);
    }
  };

  const submitAddress = (e) => {
    e.preventDefault();
    addAdress({
      name,
      number,
      country,
      state,
      addressType,
      pinCode,
      addressLine1,
      addressLine2,
      landmark,
      city,
    })
    setShowFirstForm(true);
    setShowForm(false);
  };

  return (
    <div className="add-full">
      {showFirstForm && (
        <form onSubmit={submitForm1} onClick={(e) => e.stopPropagation()}>
          <div className="flex w-full  justify-between items-center">
            <h1 className="text-xl font-semibold">Add New Address</h1>
            <div className="w-8 h-8 p-2 hover:bg-gray-100 rounded-full cursor-pointer" onClick={() => setShowForm(false)}>
              <IoMdClose className="w-full h-full" />
            </div>
          </div>
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="FULL NAME"
            variant="outlined"
            placeholder="Full Name"
            onChange={(e) => setName(e.target.value)}
            required
          />

          <PhoneNumberInput
            value={number}
            setValue={setNumber}
            setPhoneCount={setPhoneCount}
          />
          {!isNum && <p className="text-red-500">Number is required</p>}

          <Autocomplete
            autoComplete={false}
            value={country}
            onChange={(
              event,
              newValue
            ) => {
              setCountry(newValue);
            }}
            id="controllable-states-demo"
            options={options}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Country"
                variant="outlined"
              />
            )}
          />

          <Autocomplete
            autoComplete={false}
            id="combo-box-demo"
            value={state}
            onChange={(
              event,
              newValue
            ) => {
              setState(newValue);
            }}
            options={states}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="State"
                variant="outlined"
              />
            )}
          />

          <button className="bg-primary text-white w-32 h-12 shadow-md self-end mt-5" type="submit" >
            NEXT
          </button>
        </form>
      )}

      {!showFirstForm && (
        <form
          onSubmit={submitAddress}
          onClick={(e) => e.stopPropagation()}
          className="nextForm"
        >
          <div className="flex w-full  justify-between items-center">
            <h1 className="text-xl font-semibold">Add New Address</h1>
            <div className="w-8 h-8 p-2 hover:bg-gray-100 rounded-full cursor-pointer" onClick={() => setShowForm(false)}>
              <IoMdClose className="w-full h-full" />
            </div>
          </div>
          <FormControl variant="outlined" required>
            <InputLabel id="demo-simple-select-outlined-label">
              ADDRESS TYPE
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={addressType}
              onChange={(e) => setAddressType(e.target.value)}
              label="Age"
            >
              <option selected ria-label="None" value="">
                Select
              </option>
              <option selected={true} value={"Home"}>
                Home
              </option>
              <option value={"Office"}>Office</option>
              <option value={"Store"}>Store</option>
            </Select>
          </FormControl>

          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="Pincode"
            variant="outlined"
            placeholder="PINCODE"
            onChange={(e) => setPinCode(e.target.value)}
            type="number"
            required
          />
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="FLAT, HOUSE NO., BUILDING, COMPANY,"
            variant="outlined"
            onChange={(e) => setAddressLine1(e.target.value)}
            placeholder="FLAT, HOUSE, BUILDING, COMPANY"
            required
          />
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="AREA, COLONY, STREET, SECTOR, VILLAGE"
            variant="outlined"
            placeholder="AREA, COLONY, STREET, SECTOR, VILLAGE"
            onChange={(e) => setAddressLine2(e.target.value)}
            required
          />
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="LANDMARK"
            variant="outlined"
            placeholder="LANDMARK"
            onChange={(e) => setLanmark(e.target.value)}
            required
          />
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="TOWN/CITY"
            variant="outlined"
            placeholder="TOWN/CITY"
            onChange={(e) => setCity(e.target.value)}
            required
          />

          <button className="bg-primary text-white w-32 h-12 shadow-md self-end mt-5" type="submit" >
            Add
          </button>
        </form>
      )}
    </div>
  );
};

export default connect(null, { addAdress })(AddressForm)

