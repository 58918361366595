// @ts-nocheck
import {
  ADD_ADDRES,
  GET_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
} from "../actions/types";
import { produce } from "immer";

const addAddress = (state = [], action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_ADDRES:
        draft.push(action.payload);
        break;
      case GET_ADDRESS:
        return [...action.payload];
      case UPDATE_ADDRESS:
        let index = draft.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) draft[index] = { ...action.payload };
        break;
      case DELETE_ADDRESS:
        let dindex = draft.findIndex((item) => item.id === action.payload);
        if (dindex !== -1) draft.splice(dindex, 1);
        break;
      default:
        return state;
    }
  });
};

export default addAddress;
