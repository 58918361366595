import React, { useEffect } from "react";
import { notification } from "../../actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'

const Msg = ({ notification, msg }) => {

  useEffect(() => {
    if (msg.msg && msg.success) {
      toast.success(msg.msg, { theme: "dark" });
    } else if (msg.msg && msg.err) {
      toast.error(msg.msg, { theme: "dark" });
    } else if (msg.msg && msg.warning) {
      toast.warning(msg.msg, { theme: "dark" });
    } else if (msg.msg && !msg.err && !msg.warning && !msg.success) {
      toast.info(msg.msg, { theme: "dark" })
    }
    setTimeout(() => {
      notification({ msg: "", err: false, warning: false, success: false });
    }, 2000);
  }, [msg.msg]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      draggable
      icon={true}
      limit={3}
      toastStyle={{background: '#08263F', color: 'white'}}
    />
  );
};


const mapStateToProps = (state) => {
  return { msg: state.notification }
}

export default connect(mapStateToProps, { notification })(Msg)
