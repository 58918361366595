import { db } from '../firebase';
import { ADD_ADDRES, GET_ADDRESS, MSG, ADD_SINGLE_ADDRESS, DELETE_ADDRESS, UPDATE_ADDRESS } from './types';

export const notification = (msg) => {
  return { type: MSG, payload: msg }
}

export const addAdress = (data) => async (dispatch, getState) => {
  const userId = getState().user?.user?.id;
  const addressRef = db.users.doc(userId).collection("ADDRESS").doc();
  addressRef.set({
    ...data,
    id: addressRef.id
  })
    .then(() => {
      dispatch({ type: ADD_ADDRES, payload: { ...data, id: addressRef.id } });
      dispatch(notification({ msg: "Address added", success: true }));
    })
    .catch((err) => {
      console.log(err);
      dispatch(notification({ msg: "Failed to add address", err: true }));
    });
}


export const getAddresses =
  () => async (dispatch, getState) => {
    const userId = getState().user.user.id;
    db.users
      .doc(userId)
      .collection("ADDRESS")
      .get()
      .then((snapshot) => {
        dispatch({
          type: GET_ADDRESS,
          payload: snapshot.docs.map(db.formatedDoc),
        });
      });
  };

export const deleteAdress =
  (id) => async (dispatch, getState) => {
    const userId = getState().user.user.id;
    db.users
      .doc(userId)
      .collection("ADDRESS")
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: DELETE_ADDRESS, payload: id });
        dispatch(notification({ msg: "Address deleted", success: true }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(notification({ msg: "Unable to delete", err: true }));
      });
  };


//update specific address

export const updateAddress = (id, data) => async (dispatch, getState) => {
  const userId = getState().user.user.id;
  const newData = { ...data, id: id };
  db.users
    .doc(userId)
    .collection("ADDRESS")
    .doc(id)
    .update({ ...newData })
    .then(() => {
      dispatch({ type: UPDATE_ADDRESS, payload: { ...data, id } });
      dispatch(notification({ msg: "Address Updated", success: true }));
    })
    .catch((err) => {
      console.log(err);
      dispatch(notification({ msg: "Unable to update address", err: true }));
    });
};
