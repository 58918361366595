import {
  ADD_TO_CART,
  GET_CART,
  DELETE_CART_ITEM,
  UPDATE_CART_QUANITY,
} from "./types";
import { notification } from ".";
import localdb from '../localDB'
import { showCart } from "./cart";


export const addLocalCartItems = (data) => async (dispatch, getState) => {
  const cart = getState().cart;
  const itemExists = cart.find(
    (item) =>
      item.product.id === data.product.id
  );
  if (itemExists) {
    localdb.cart.where('id').equals(data.product.id).modify({ quantity: data.quantity });
    dispatch({ type: UPDATE_CART_QUANITY, payload: { id: data.product.id, quantity: data.quantity } });
  } else if (!itemExists) {
    localdb.transaction('rw', localdb.cart, () => {
      localdb.cart.put({ id: data.product.id, ...data });
    }).then(() => {
      dispatch({ type: ADD_TO_CART, payload: { ...data } })
    })
      .catch(function (e) {
        console.log(e);
      });
  }
  dispatch(showCart(true));
}

export const getLocalCartItems = () => async (dispatch, getState) => {
  const all = await localdb?.cart.toArray();
  // console.log(all)
  dispatch({ type: GET_CART, payload: all });
}

export const updateLocalCartItems = (productID, quantity) => async (dispatch, getState) => {
  localdb.cart.where('id').equals(productID).modify({ quantity });
  dispatch({ type: UPDATE_CART_QUANITY, payload: { id: productID, quantity } });

}

export const deleteLocalCartItems = (productID) => async (dispatch, getState) => {
  localdb.cart.where('id').equals(productID).delete();
  dispatch({ type: DELETE_CART_ITEM, payload: productID });
  dispatch(notification({ msg: "cart item deleted", success: true }));

}

