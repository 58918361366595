import { AUTH_STATE, LOG_OUT, MSG, OTP, SIGN_IN, NOT_SINGUPED, SHOW_OTP, GET_ADDRESS } from "./types";
import firebase, { db, auth } from '../firebase'
import { history } from '../history'
import { toast } from "react-toastify";

// action creator for sending notification
export const notification = (payload) => {
  return { type: MSG, payload };
};

// action creator for showing the otp input if user is present db or not
export const showOtp = (boolean) => {
  return { type: SHOW_OTP, payload: boolean }
}

// const user =  {
//   id: '123213',
//   name: 'user',
//   email: 'user@.com',
//   phoneNumber: '123456789',
//   picUrl: '',
//   role: 'user',
// }

export const login = (number, setIsLoading, setShowOTP) => async (dispatch, getState) => {
  let phoneNumber = number;
  await sendOtp(phoneNumber);
  toast.success('OTP sent', { theme: 'dark' })
  setIsLoading(false);
  setShowOTP(true);
};

export const sendOtp = async (phoneNumber) => {
  let appVerifier = window.recaptchaVerifier;
  return await firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(function (confirmationResult) {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      return confirmationResult;
    })
    .catch(function (error) {
      console.log(error);
    })
};

export const createNewUser = async (newUser) => {
  const docref = db.users.doc(newUser.id);
  return await docref.set({ ...newUser }).then(() => newUser).catch((err) => console.log(err));
}


export const sigin = (phoneNumber, email, name, uid, history) =>
  async (dispatch) => {
    const newUser = {
      id: uid,
      name,
      email,
      phoneNumber,
      picUrl: "",
      role: "user",
      lastSignInTimeInMilliSecond: Date.now(),
    };
    await createNewUser(newUser).catch((err) => {
      console.log(err);
      dispatch(notification({ msg: "Something went wrong", err: true }));
    });
    dispatch({ type: OTP, payload: newUser });
    history.goBack();
  };

// export const sigin = (phoneNumber, email, name, uid) => async dispatch => {
//   // console.log(uid);
//   const newUser = {
//     name,
//     email,
//     phoneNumber,
//     role: 'user',
//     picUrl: '',
//     id: uid,
//     isBlocked: false
//   }

//   const docref = db.users.doc(uid);
//   docref.set({
//     ...newUser,
//   }).then(() => {
//     console.log("new user added");
//     dispatch({ type: OTP, payload: newUser });
//     history.goBack();
//   })
//     .catch((err) => {
//       console.log(err)
//       dispatch(notification({ msg: "Something went wrong", err: true }))
//       setTimeout(() => {
//         dispatch(notification({ msg: "", err: false }))
//       }, 2000);

//     })
// }

export const submitOtp = (otp, history, setIsLoading) => async dispatch => {
  let redirect = false;
  let optConfirm = window.confirmationResult;
  optConfirm
    .confirm(otp)
    .then((result) => {
      let user = result.user;
      db.users.where('phoneNumber', '==', user.phoneNumber).get().then((data) => {
        setIsLoading(false);
        if (data.docs[0]) {
          // auth_state will take care of below code from app.tsx
          // dispatch({ type: OTP_CONFIRMED, payload: data.docs[0].data() });
        } else {
          dispatch({ type: NOT_SINGUPED, payload: result.user });
          // navigate('/signup', { replace: true });
          history.push({
            pathname: '/signup',
            state: { uid: result.user.uid }
            
          });
        }
      }).catch((err) => {
        console.log(err);
        dispatch(notification({ msg: "Something went wrong", err: true }));
      })
    }).catch(function (error) {
      console.log(error);
      dispatch(notification({ msg: "Wrong OTP", err: true }))
      setTimeout(() => {
        dispatch(notification({ msg: "", err: false }))
      }, 2000);
    });
}

export const userStateChanged = (user) => {
  // console.log(user)
  return { type: AUTH_STATE, payload: user }
}

export const logout = () => async dispatch => {
  await auth.signOut();
  dispatch({ type: LOG_OUT, payload: {} });
  dispatch({ type: SHOW_OTP, payload: false });
  dispatch({ type: GET_ADDRESS, payload: [] })
  dispatch(notification({ msg: "logged out successfully", success: true }));
  history.push('/login')
}

