import { ADD_TO_CART, SHOW_CART, GET_CART, CART_TOTAL, DELETE_CART_ITEM, UPDATE_CART_QUANITY } from './types'
import { db } from '../firebase'
import localdb from '../localDB'
import { notification } from './index'

export const showCart = (boolean) => {
  return { type: SHOW_CART, payload: boolean }
}

export const addToCart = (data) => async (dispatch, getState) => {
  const userID = getState().user?.user?.id;
  const userDbRef = db.users.doc(userID);
  const cart = getState().cart;
  let itemExists = cart.find((item) => item.product.id === data.product.id);
  if (itemExists) {
    if (data.quantity === itemExists.quantity) {
      dispatch(showCart(true))
      return;
    } else {
      db.users.
        doc(userID)
        .collection("CARTITEMS")
        .doc(itemExists.product.id)
        .update({
          quantity: data.quantity,
        })
        .then(() => {
          dispatch({ type: UPDATE_CART_QUANITY, payload: { id: data.product.id, quantity: data.quantity } })
          dispatch(showCart(true));
        })
        .catch((err) => {
          console.log(err);
          dispatch(notification({ msg: "Something went wrong", err: true }));
        })
    }
  } else {
    db.users
      .doc(userID)
      .collection("CARTITEMS")
      .doc(data.product.id)
      .set({
        ...data,
      })
      .then(() => {
        dispatch({ type: ADD_TO_CART, payload: { ...data } })
        dispatch(showCart(true));
      })
      .catch((err) => {
        console.log(err)
        dispatch(notification({ msg: "Something went wrong", err: true }));
      });
  }

}

const getLocalItems = async () => {
  return await localdb?.cart.toArray();
}

export const getCartItems = () => async (dispatch, getState) => {
  const userID = getState().user?.user?.id;
  // delete All local cart items from state
  await getLocalItems().then(async (all) => {
    if (all.length > 0) {
      for await (const item of all) {
        dispatch({ type: DELETE_CART_ITEM, payload: item.product.id });
      }
    }
  });


  db.users
    .doc(userID)
    .collection('CARTITEMS')
    .get()
    .then(async (query) => {
      let cartItems = query.docs.map(db.formatedDoc);

      let items = [];
      for (let i = 0; i < cartItems.length; i++) {
        let item = cartItems[i];
        await db.products.doc(item.product.id).get().then(doc => {
          if (doc.exists) {
            let product = doc.data();
            items.push({ ...item, product });
          }
        })
      }
      dispatch({
        type: GET_CART,
        payload: items,
      });
    }).then(async () => {
      // compare and update or add new items in state
      await getLocalItems().then(async (all) => {
        if (all.length > 0) {
          for await (const item of all) {
            // delete the local item from indexDB (localStorage)
            localdb.cart.where('id').equals(item.product.id).delete();
            if (getState().cart.find((data) => data.product.id === item.product.id)) {
              dispatch({ type: UPDATE_CART_QUANITY, payload: { id: item.product.id, quantity: item.quantity } });
            } else {
              dispatch(addToCart(item));
            }
          }
        }
      });
    });


}

export const deleteCartItem = (id) => (dispatch, getState) => {
  const userID = getState().user?.user?.id;
  db.users
    .doc(userID)
    .collection('CARTITEMS')
    .doc(id)
    .delete()
    .then(() => {
      dispatch({ type: DELETE_CART_ITEM, payload: id })
      dispatch(notification({ msg: "Product removed from cart", err: false }))
    }).catch((err) => {
      dispatch(notification({ msg: "Unable to remove the product from cart", err: false }))
      console.log(err)
    })

}

export const updateCartQauntity = (id, quantity) => async (dispatch, getState) => {
  const userID = getState().user?.user?.id;
  db.users.
    doc(userID)
    .collection('CARTITEMS')
    .doc(id)
    .update({
      quantity
    })
    .then(() => {
      dispatch({ type: UPDATE_CART_QUANITY, payload: { id, quantity } })
    }).catch((err) => {
      console.log(err)
    })
}

export const getCartTotal = () => (dispatch, getState) => {
  const cartItems = getState().cart;
  const total = cartItems.reduce((total, itm) => itm.product.stock !== 0 ? (total + itm.product.discountedMrp * itm.quantity) : total, 0);
  dispatch({ type: CART_TOTAL, payload: total })
}