import produce from 'immer'
import { ADD_TO_CART, GET_CART, DELETE_CART_ITEM, UPDATE_CART_QUANITY } from '../actions/types'

const cartReducer = (state = [], action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_TO_CART:
        draft.push(action.payload);
        break;
      case DELETE_CART_ITEM:
        let dindex = draft.findIndex((item) => item.product.id === action.payload);
        if (dindex !== -1) draft.splice(dindex, 1);
        break;
      case UPDATE_CART_QUANITY:
        let index = draft.findIndex((item) => item.product.id === action.payload.id);
        if (index !== -1) draft[index] = { ...draft[index], quantity: action.payload.quantity };
        break;
      case GET_CART:
        return [...action.payload]
      default:
        return state
    }
  })
}
export default cartReducer;