import { Link, useLocation } from "react-router-dom";
import Scroller from '../Scroller';
import { IoMdClose } from 'react-icons/io'
import { history } from '../../history';
const Privcy = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="w-full flex flex-col">
      <IoMdClose className="text-3xl ml-auto cursor-pointer" onClick={() => history.goBack()} />
      <div className="w-full flex items-center md:justify-evenly border-b border-b-black overflow-hidden py-5">
        <Scroller>
          <Link to="/privacy-policy" replace>
            <h1 className={`${path === "/privacy-policy" ? "text-black font-semibold" : "text-gray-400"} min-w-[144px] mx-5 text-center whitespace-nowrap select-none text-xl`}>
              Privacy Policy
            </h1>
          </Link>
          <Link to="/terms&conditions" replace>
            <h1 className={`${path === "/terms&conditions" ? "text-black font-semibold" : "text-gray-400"} min-w-[144px] mx-5 text-center whitespace-nowrap select-none text-xl`}>
              Terms of Service
            </h1>
          </Link>
          <Link to="/return-policy" replace>
            <h1 className={`${path === "/return-policy" ? "text-black font-semibold" : "text-gray-400"} min-w-[144px] mx-5 text-center whitespace-nowrap select-none text-xl`}>
              Returns
            </h1>
          </Link>
        </Scroller>
      </div>
    </div >
  );
};

export default Privcy;
